import React from 'react';
import { Link } from 'react-router-dom';
import './tou.css';
const TermsOfUse = () => {
    return (
        <div className="terms-of-use">
            <h1>Terms of Use</h1>
            <p>Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern [Company Name]'s relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>
            
            <h2>Use of the Site</h2>
            <p>This website may be used for your own private purposes and in accordance with these terms of use.</p>
            
            <h2>Site Uptime</h2>
            <p>We take all reasonable steps to ensure that this Website is available 24 hours every day, 365 days per year. However, websites do sometimes encounter downtime due to server and other technical issues. Therefore, we will not be liable if this website is unavailable at any time.</p>
            
            <h2>Visitor Conduct</h2>
            <p>With the exception of personally identifiable information, the use of which is covered under our Privacy Policy, any material you send or post to this website shall be considered non-proprietary and not confidential.</p>
            
            <h2>Site Content</h2>
            <p>While we endeavour to ensure that all content provided on the website is correct at the time of publication, no responsibility is accepted by or on behalf of [Company Name] for any errors, omissions, or inaccurate content on the website.</p>
            
            <h2>Links to and From Other Websites</h2>
            <p>Any links to third party websites located on this Website are provided for your convenience only. We have not reviewed each third party website and have no responsibility for such third party websites or their content.</p>
            
            <div className="back-to-home">
                <Link to="/">Back to Home</Link>
            </div>
        </div>
    );
};

export default TermsOfUse;
