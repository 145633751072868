import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const HomeScreen = () => {
    const [propertiesForSale, setPropertiesForSale] = useState([]);
    const [propertiesForLease, setPropertiesForLease] = useState([]);

    // Fetch properties for sale and lease
    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const saleResponse = await fetch(`https://v9vndtqma4.execute-api.us-east-1.amazonaws.com/map_condo/?sale=Sale`);
                const saleProperties = await saleResponse.json();
                setPropertiesForSale(saleProperties.features.slice(6, 11));

                const leaseResponse = await fetch(`https://v9vndtqma4.execute-api.us-east-1.amazonaws.com/map_condo/?sale=Lease`);
                const leaseProperties = await leaseResponse.json();
                setPropertiesForLease(leaseProperties.features.slice(0, 5));
            } catch (error) {
                console.error("Failed to fetch properties", error);
            }
        };

        fetchProperties();
    }, []);

    // Render properties list
    const renderPropertiesList = (properties) => {
        return properties.map((property, index) => (
            <li key={index}>
                <div className="property">
                    <Link to={`/property/${property.properties.mls_id}`}>
                        <img className="property-image" src={`https://dgqt7cj736luu.cloudfront.net/${property.properties.mls_id}/1.jpg`} onError={(e) => e.target.src = '../images/default.png'} alt={property.properties.address} />
                    </Link>
                    <div className="property-info">
                        <div className="property-name">
                            <Link to={`/property/${property.properties.mls_id}`}>
                                {property.properties.address}
                            </Link>
                        </div>
                        <div className="property-city">
                            {property.properties.type}
                        </div>
                        <div className="property-price">
                            ${parseFloat(property.properties.list_price).toLocaleString('en-US')}
                        </div>
                    </div>
                </div>
            </li>
        ));
    };

    return (
        <div>
            <div className="hero-header">
                <h1>Welcome to Home AI</h1>
                <p>Find your dream home today</p>
                <input type="text" id="search-input" placeholder="Search properties..." />
                <button id="search-button">Search</button>
            </div>
            <div className="HomeTitle">Hottest Condos For Sale</div>
            <ul className="properties">
                {renderPropertiesList(propertiesForSale)}
            </ul>
            <div className="HomeTitle">Hottest Condos For Lease</div>
            <ul className="properties">
                {renderPropertiesList(propertiesForLease)}
            </ul>
        </div>
    );
};

export default HomeScreen;
