import React, { useEffect, useState } from 'react';
import { Authenticator, useAuthenticator, CheckboxField } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import './formstyles.css';

export default function App() {
    const navigate = useNavigate();
    const [userSignedIn, setUserSignedIn] = useState(false);

    useEffect(() => {
        checkUserAuthentication();
    }, []);

    const checkUserAuthentication = async () => {
        try {
            await getCurrentUser();
            setUserSignedIn(true);
        } catch (error) {
            setUserSignedIn(false);
        }
    };

    useEffect(() => {
        if (userSignedIn) {
            // Perform the redirect and reload
            window.location.href = '/';
        }
    }, [userSignedIn]);

    const CustomSignUpFooter = () => {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px', padding: '20px' }}>
                By signing up, you agree to the <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: 'orange' }}>Terms and Conditions</a>
            </div>
        );
    };

    return (
        <div className='auth-wrapper'>
            <Authenticator
                initialState="signIn"
                components={{
                    SignUp: {
                        FormFields() {
                            const { validationErrors } = useAuthenticator();

                            return (
                                <>
                                    <Authenticator.SignUp.FormFields />
                                    <CheckboxField
                                        errorMessage={validationErrors.acknowledgement}
                                        hasError={!!validationErrors.acknowledgement}
                                        name="acknowledgement"
                                        value="yes"
                                        label="I agree with the Terms and Conditions"
                                    />
                                </>
                            );
                        },
                        Footer: CustomSignUpFooter,
                    },
                }}
                services={{
                    async validateCustomSignUp(formData) {
                      if (!formData.acknowledgement) {
                        return {
                          acknowledgement: 'You must agree to the Terms and Conditions',
                        };
                      }
                    },
                  }}
            >
                {({ signOut, user }) => (
                    <main>
                        <h1>You are currently signed in.</h1>
                    </main>
                )}
            </Authenticator>
        </div>
    );
}
