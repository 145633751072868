import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import PropertyScreen from './screens/PropertyScreen';
import MapScreen from './screens/MapScreen';
import './App.css';
import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import '@aws-amplify/ui-react/styles.css';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import LoginScreen from './screens/LoginScreen';
import TermsOfUse from './screens/TermsOfUse';
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
      <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
      }}>
          <div style={{
              background: 'white',
              padding: '20px',
              borderRadius: '8px',
          }}>
              {children}
              <button onClick={onClose} style={{
                  marginTop: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
              }}>Close</button>
          </div>
      </div>
  );
};

Amplify.configure(awsExports);
const App = () => {

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        checkCurrentUser();
    }, []);

    const checkCurrentUser = async () => {
        try {
            await getCurrentUser();
            setIsUserLoggedIn(true);
        } catch (error) {
            setIsUserLoggedIn(false);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut();
            setIsUserLoggedIn(false);
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };


  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const handleMapSearchClick = (event) => {
    event.preventDefault(); // Prevent default if using <a>
    setIsMapModalOpen(true);
};
return (
  <Router>
      <div className="grid-container">
      <header>
        <div className="brand">
            <Link to="/">Home AI</Link>
        </div>
        <div>
            <Link to="/map" style={{ cursor: 'pointer', marginRight: '20px', marginLeft: '20px'}}>Map Search</Link>
            {/* Toggle between Profile and Log-In */}
            {isUserLoggedIn ? (
                <>
                    <a href="/" onClick={handleLogout} style={{ cursor: 'pointer'}}>
                        Log-Out
                    </a>
                </>
            ) : (
                <Link to="/login" style={{ cursor: 'pointer', marginRight: '20px'}}>Log-In</Link>
            )}
        </div>
    </header>
          <main id="root">
          <Routes>
            <Route path="/" element={<HomeScreen />} exact />
            <Route path="/property/:id" element={<PropertyScreen />} />
            <Route path="/map" element={<MapScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/terms" element={<TermsOfUse />} />
            {/* <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<ErrorScreen />} /> */}
          </Routes>
          </main>
          <footer>
              All rights reserved @ 2024
          </footer>
      </div>
  </Router>
);
};
// This component decides which property screen to render based on the ID


export default App;
