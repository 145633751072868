import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import bedSvg from '../images/bed.svg';
import bathSvg from '../images/bath.svg';
import garageSvg from '../images/garage.svg';
import '../App.css';
const PropertyScreen = () => {
    const { id } = useParams(); // Capture the 'id' parameter from the URL
    const [property, setProperty] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showCarousel, setShowCarousel] = useState(false);
    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const upperCaseId = id.toUpperCase();
                const response = await fetch(`https://3140haxgz1.execute-api.us-east-1.amazonaws.com/beta/?mls_id=${upperCaseId}`);
                const json = await response.json();
                setProperty(json);
            } catch (error) {
                console.error("Failed to fetch property details", error);
            }
        };

        fetchProperty();
    }, [id]);

    if (!property) {
        return <div></div>;
    }
    
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const bucketUrl = 'https://dgqt7cj736luu.cloudfront.net/'; // Replace with your actual bucket URL
    const numberOfImages = 40; // Assuming each property has 20 images
    const formattedPrice = parseFloat(property.list_price).toLocaleString('en-US');
    const list_date = new Date(property.listing_entry_date);
    const update = new Date(property.updated_timestamp)
    const formattedListDate = `${months[list_date.getMonth()]} ${list_date.getDate()}, ${list_date.getFullYear()}`;
    const formattedUpDate = `${months[update.getMonth()]} ${update.getDate()}, ${update.getFullYear()}`;
    const utilities = [];
    if (property.water_included === 'Y') utilities.push('Water');
    if (property.hydro_included === 'Y') utilities.push('Electricity');
    if (property.heat_included === 'Y') utilities.push('Heat');
    const utilitiesIncluded = utilities.length > 0 ? utilities.join(', ') : '-';
    const amenities = [];
    if (property.building_amenities1 !== '') amenities.push(`${property.building_amenities1}`);
    if (property.building_amenities2 !== '') amenities.push(`${property.building_amenities2}`);
    if (property.building_amenities3 !== '') amenities.push(`${property.building_amenities3}`);
    if (property.building_amenities4 !== '') amenities.push(`${property.building_amenities4}`);
    if (property.building_amenities5 !== '') amenities.push(`${property.building_amenities5}`);
    if (property.building_amenities6 !== '') amenities.push(`${property.building_amenities6}`);
    const amenitiesIncluded = amenities.length > 0 ? amenities.join(', ') : '-';

    const carouselImages = Array.from({ length: numberOfImages }, (_, index) => (
        <div 
          key={index} 
          className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`} 
          style={{ display: index === currentImageIndex ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
        >
          <img 
            src={`${bucketUrl}${id}/${index + 1}.jpg`} 
            onError={(e) => e.target.src = '../images/default.png'} 
            alt={`Property ${index + 1}`} 
          />
        </div>
    ));
    

    // Carousel image navigation
    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + numberOfImages) % numberOfImages);
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % numberOfImages);
    };

    // const carouselImages = Array.from({ length: numberOfImages }).map((_, index) => (
    //     <div key={index} className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`} style={{ display: index === currentImageIndex ? 'block' : 'none' }}>
    //         <img src={`https://dgqt7cj736luu.cloudfront.net/${id.toUpperCase()}/${index + 1}.jpg`} onError={(e) => e.target.src = '../images/default.png'} alt={`Property image ${index + 1}`} />
    //     </div>
    // ));

    return (
        <div>
            <div className="gallery-container">
                    <div className="large-image-container">
                        <img src={`https://dgqt7cj736luu.cloudfront.net/${id}/1.jpg`} alt="Main" onError={(e) => e.target.style.display='none'}/>
                    </div>
                    <div className="small-images-container">
                        <img src={`https://dgqt7cj736luu.cloudfront.net/${id}/2.jpg`} alt="Sub 1" onError={(e) => e.target.style.display='none'}/>
                        <img src={`https://dgqt7cj736luu.cloudfront.net/${id}/3.jpg`} alt="Sub 2" onError={(e) => e.target.style.display='none'}/>
                        <img src={`https://dgqt7cj736luu.cloudfront.net/${id}/4.jpg`} alt="Sub 3" onError={(e) => e.target.style.display='none'}/>
                        <img src={`https://dgqt7cj736luu.cloudfront.net/${id}/5.jpg`} alt="Sub 4" onError={(e) => e.target.style.display='none'}/>
                        <button id="see-all-pictures" type="button" onClick={() => setShowCarousel(true)}>See all pictures</button>

                    </div>
                </div>
                <div className="single_property">
                <div id="image-carousel" className="image-carousel" style={{display: showCarousel ? 'block' : 'none'}}>
                    <button id="close-carousel" className="close-carousel" onClick={() => setShowCarousel(false)}>&times;</button>
                    {carouselImages}
                    <button id="carousel-prev" onClick={handlePrevClick}>&lt;</button>
                    <button id="carousel-next" onClick={handleNextClick}>&gt;</button>
                </div>


                    <div className="property-and-contact-container">
                        <div className="property-details-container">
                            <div className="property-card">
                                <div className="property-header">
                                    <h2 className="property-title">Unit {property.apt_unit} - {property.address}</h2>
                                    <div className="property-location">{property.municipality}</div>
                                    <div className="property-type">{property.type1}</div>
                                </div>
                                
                                <div className="property-details">
                                    <div className="property-detail">
                                        <img src={bedSvg} alt="Bedrooms"/>
                                        <span>{property.bedrooms} Bedrooms</span>
                                    </div>
                                    <div className="property-detail">
                                    <img src={bathSvg} alt="Bedrooms"/>
                                        <span>{property.washrooms} Bathrooms</span>
                                    </div>
                                    <div className="property-detail">
                                    <img src={garageSvg} alt="Bedrooms"/>
                                        <span>{property.parking_spaces} Garage</span>
                                    </div>
                                </div>

                             
                                <div className="property-pricing">
                                    <div className="property-price">
                                        Listed for: <span className="price">${formattedPrice}</span>
                                    </div>  
                                </div>
                            </div>
                            <div className="property-card">
                                <h4> Key Details for {property.address}, {property.community}, {property.municipality}</h4>
                                <div className="column">
                                    <span>Tax: ${property.taxes ? `${property.taxes}` : '-'} / {property.tax_year ? `${property.tax_year}` : '-'} </span>
                                    <span>Property Type: {property.type1 ? `${property.type1}` : '-'} </span>
                                    <span>Property Maintenance: {property.maintenance ? `${property.maintenance}` : '-'}  </span>
                                    <span>Building Age: {property.approx_age ? `${property.approx_age}` : '-'}</span>
                                    <span>Size: {property.approx_square_footage ? `${property.approx_square_footage}` : '-'} </span>
                                    <span>Parking: {property.parking_drive ? `${property.parking_drive}` : '-'} </span>
                                    <span>Utilities Included: {utilitiesIncluded}</span>
                                    <span>Locker: {property.locker ? `${property.locker}` : '-'} </span>
                                    <span>Basement: {property.basement1 ? `${property.basement1}` : '-'} </span>
                                    <span>Building Amenities: {amenitiesIncluded}</span>
                                </div>
                                <div className="column">
                                    <span>Listing #: {property.mls_id}</span>
                                    <span>Data Source: TRREB </span>
                                    <span>Days on Market: {property.days_on_market} </span>
                                    <span>Listing Brokerage: {property.list_brokerage} </span>
                                    <span>Listing Date: {formattedListDate} </span>
                                    <span>Updated Date: {formattedUpDate} </span>
                                </div>
                            </div>
                            <div className="property-card">
                                <h3> Property Description: </h3>
                                <span>{property.remarks_for_clients}</span>
                            </div>
                            
                        </div>
                        <div className="contact-container">
                            <form action="" className="contact-right">
                                <div className="contact-title">
                                    <h3>Contact Agent?</h3>
                                 
                                </div>
                                <input type="text" name="name" placeholder="Your Name" className="contact-inputs required" />
                                <input type="email" name="email" placeholder="Your Email" className="contact-inputs required" />
                                <textarea name="message" placeholder="Your Message" className="contact-inputs required"></textarea>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default PropertyScreen;
